import React from "react";
import Spinner from "components/Spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Button, CloseButton } from "react-bootstrap";
import "./styles/support-dashboard.css";
import "./styles/progress-circle.css";
import UploadClaim from "./UploadClaim";
import EligibleExpenses from "./EligibleExpenses";
import toastr from "toastr";

export default class EmployeeBenefit extends React.Component<{
  employeeId: string;
  policyId: string;
}, any> {
  state = {
    loaded: false,
    plans: null,
    employee: null,
    company: null,
    HSAClaimCount: 0,
    LSAClaimCount: 0,
    isHSAModalOpen: false,
    isLSAModalOpen: false,
    accountType: null,
    policyNo: null,
    enableWellness: false,
    showEligibleExpenses: false,
    allowLsaDependentClaims: false,
    hasFlex: false,
    hasAdditionalAccounts: false,
    hasCoveragePay: false,
    allowAdditionalAccountsDepClaim: false,
  };

  componentDidMount() {
    this.startLoading();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.employeeId !== prevProps.employeeId ||
      this.props.policyId !== prevProps.policyId
    ) {
      this.startLoading();
    }
  }

  async startLoading() {
    this.setState({ loaded: false });

    try {
      const [benefitResponse, employeeResponse] = await Promise.all([
        this.fetchBenefitDetails(),
        this.fetchEmployeeDetails(),
      ]);

      this.setState({
        loaded: true,
        policyNo: benefitResponse.data.policyNo,
        plans: benefitResponse.data.spendingAccounts,
        HSAClaimCount: benefitResponse.data.hsaClaimCount,
        LSAClaimCount: benefitResponse.data.lsaClaimCount,
        enableWellness: benefitResponse.data.enableWellness,
        allowLsaDependentClaims: benefitResponse.data.allowLsaDependentClaims,
        hasFlex: benefitResponse.data.hasFlex,
        hasAdditionalAccounts: benefitResponse.data.hasAdditionalAccounts,
        allowAdditionalAccountsDepClaim: benefitResponse.data.allowAdditionalAccountsDepClaim,
        hasCoveragePay: benefitResponse.data.hasCoveragePay,
        employee: employeeResponse.data.employee,
        company: employeeResponse.data.company,
      });
    } catch (error) {
      toastr.error("Failed to load data", "Error");
    }
  }

  async fetchBenefitDetails() {
    return axios.get(
      `/api/supportemployee/getbenefit?policyId=${this.props.policyId}&employeeId=${this.props.employeeId}`
    );
  }

  async fetchEmployeeDetails() {
    return axios.get(
      `/api/supportemployee/getemployee?policyId=${this.props.policyId}&employeeId=${this.props.employeeId}`
    );
  }

  displayHSAModal = () => this.setState({ isHSAModalOpen: true });
  closeHSAModal = () => this.setState({ isHSAModalOpen: false });
  displayLSAModal = () => this.setState({ isLSAModalOpen: true });
  closeLSAModal = () => this.setState({ isLSAModalOpen: false });

  checkShowModal = (type) => {
    if (type === "HSA") {
      this.setState({ isHSAModalOpen: true, accountType: type });
    } else if (type === "LSA") {
      this.setState({ isLSAModalOpen: true, accountType: type });
    }
  };

  checkShowshowEligibleExpenses = (type) => {
    this.setState((prevState) => ({
      accountType: type,
      showEligibleExpenses: !prevState.showEligibleExpenses,
    }));
  };

  renderHSAModal() {
    if (!this.state.isHSAModalOpen) return null;
    return (
      <UploadClaim
        employeeId={this.props.employeeId}
        policyId={this.props.policyId}
        type={this.state.accountType}
      />
    );
  }

  renderLSAModal() {
    if (!this.state.isLSAModalOpen) return null;
    return (
      <UploadClaim
        employeeId={this.props.employeeId}
        policyId={this.props.policyId}
        type={this.state.accountType}
      />
    );
  }

  renderEligibleExpenses() {
    if (!this.state.showEligibleExpenses) return null;
    return (
      <EligibleExpenses
        employeeId={this.props.employeeId}
        policyId={this.props.policyId}
        type={this.state.accountType}
      />
    );
  }

  renderContent() {
    if (!this.state.loaded) {
      return <Spinner />;
    }

    const accounts = this.state.plans?.map((data) => {
      let currPercent = Math.min(
        Math.round((data.currYear.available / data.currYear.limit) * 100),
        100
      );
      let prevPercent = Math.min(
        Math.round((data.prevYear?.available / data.prevYear?.limit) * 100),
        100
      );

      const currCircleClass =
        data.type === "HSA"
          ? `progress-circle progress-${currPercent}`
          : `progress-circle-green progress-${currPercent}`;

      const dataType =
        data.type === "HSA"
          ? "Health Spending Account"
          : this.state.enableWellness
          ? "Wellness Spending Account"
          : "Lifestyle Spending Account";

      return (
        <div className="column" key={data.id}>
          <div className="pd-t-30">
            <div className="text-center pd-t-15">
              <div className={currCircleClass}>
                <span>
                  <div className="tx-available-val card-font-color">
                    ${data.currYear.available.toFixed(2)}
                  </div>
                  <div className="tx-available text-uppercase card-font-color">
                    available
                  </div>
                </span>
              </div>
            </div>

            <div className="text-center">
              <div className="h6 black pd-t-10">{dataType}</div>
              <div className="h6 black">
                Current year limit: ${data.currYear.limit.toFixed(2)}
                <br />
                Previous year balance: $
                {data.prevYear?.available.toFixed(2) || "N/A"}
                <br />
                Previous year limit: ${data.prevYear?.limit.toFixed(2) || "N/A"}
              </div>
            </div>

            <div className="step3 text-center mg-t-80">
              <div className="pd-t-10">
                <Link
                  className={`btn-support btn-primary-support pd-x-25-force home-camera-${data.type.toLowerCase()}`}
                  to="#"
                  onClick={() => this.checkShowModal(data.type)}
                >
                  Make a {data.type} Claim
                </Link>
              </div>
              <div className="pd-t-10">
                <Link
                  className={`btn-support btn-support-primary-support-green pd-x-25-force home-camera-inv-${data.type.toLowerCase()}`}
                  to="#"
                  onClick={() => this.checkShowshowEligibleExpenses(data.type)}
                >
                  Eligible Expenses
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="br-mainpanel">
        <div className="row pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30" style={{marginBottom:10}}>
          <div className="col-3">Allow LSA Dependent Claims</div>
          <div className="col-9">
            {this.state.allowLsaDependentClaims ? "Yes" : "No"}
          </div>
        </div>
        <div className="row pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30" style={{marginBottom:10}}>
          <div className="col-3">Has Flex</div>
          <div className="col-9">{this.state.hasFlex ? "Yes" : "No"}</div>
        </div>
        <div className="row pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30" style={{marginBottom:10}}>
          <div className="col-3">Has Additional Accounts</div>
          <div className="col-9">
            {this.state.hasAdditionalAccounts ? "Yes" : "No"}
          </div>
        </div>

        {this.state.allowAdditionalAccountsDepClaim ? (
          <div className="row pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30" style={{marginBottom:10}}>
            <div className="col-3">Allow Additional Accounts Dependent Claims</div>
            <div className="col-9">Yes ( See Additional Accounts for further dependent eligibility )</div>
          </div>
        ) : <></>}

        {this.state.hasCoveragePay && (
          <div className="row pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30" style={{marginBottom:10}}>
            <div className="col-3">Has Coverage %</div>
            <div className="col-9">Yes</div>
          </div>
        )}

        <div className="row pd-x-20 pd-sm-x-30 pd-t-20 pd-sm-t-30" style={{marginBottom:10}}>
          <div className="col-3">Benefits</div>
          {this.state.plans?.length ? (
            <div className="col-9">
              The current benefit year goes from{" "}
              {this.state.plans[0].currYear?.startDate} to{" "}
              {this.state.plans[0].currYear?.endDate}.<br />
              The run-off deadline for the current benefit year is{" "}
              {this.state.plans[0].currYear?.runoff} at 11:59 PM MT.
            </div>
          ) : (
            <div className="col-9">No plans found for this employee.</div>
          )}
        </div>

        {this.state.plans?.length && (
          <div className="br-section-wrapper mg-b-30 no-padding mg-x-50" style={{marginBottom:10}}>
            <div className="card mg-t-35">
              <div
                className="card-header tx-medium tx-black"
                style={{ backgroundColor: "#fff" }}
              >
                {this.state.company?.name?.toUpperCase()} SPENDING ACCOUNT
              </div>
              <div className="card-body">
                <div className="br-pagebody" style={{ maxWidth: 1250 }}>
                  <div className="row">{accounts}</div>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.renderHSAModal()}
        {this.renderLSAModal()}
        {this.renderEligibleExpenses()}
      </div>
    );
  }

  render() {
    return this.renderContent();
  }
}
