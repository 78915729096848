import React from "react";
import {KTMScreen} from "ktm-ui";
import toastr from "toastr";
import {Button, Modal} from "react-bootstrap";
import moment from "moment";

export default KTMScreen(
    {name: "unfinalizeclaim"},
    class extends React.Component<any, any> {
        state = {
            reopenClaimApproval: false,
            isClaimUnfinalized: false,
            claimNo: null,
            claimResult: null,
        }
        
        setInput = (e) => {
            this.setState({claimNo: e.target.value});
        };

        handleKeyDown = (event) => {
            if (event.key === "Enter") {
                this.submitSearch();
            }
        };
        
        setReopenClaim = () => {
            this.setState({ reopenClaimApproval: true });
        }

        handleUnfinalizeConfirmModal = () => {
            this.setState({ reopenClaimApproval: false });
        }
        
        submitSearch = () => {
            if (this.state.claimNo != null && this.state.claimNo != "") {
                fetch("/api/claims/searchclaims/" + this.state.claimNo, {
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        this.setState(
                            {
                                claimResult: data.claim,
                                employeeId: data.employeeId,
                                currentBenYearId: data.currentBenYearId,
                                isClaimUnfinalized: false,
                            },
                            () => {
                                if (this.state.claimResult === undefined) {
                                    toastr.error("Claim already closed.", "Error");
                                }
                            }
                        );
                    })
                    .catch((error) => {
                        this.setState({claimResult: null, isClaimUnfinalized: false});
                        toastr.error("Search failed", "Error");
                    });
            }
        };

        unfinalizeClaim = () => {
            fetch("/api/claims/unfinalizeClaim/" + this.state.claimResult.employeeId 
                + "/" + this.state.claimResult.currentBenYearId + "/" + this.state.claimNo, 
                {
                    method: "GET",
                })
                .then((response) => response.json())
                .then((data) => {
                    this.setState(
                        {
                            isClaimUnfinalized: data.success,
                        }
                    );
                    this.handleUnfinalizeConfirmModal();
                })
                .catch((error) => {
                    this.setState({isClaimUnfinalized: false});
                    toastr.error("Update failed", "Error");
                });
        };
        
        render() {
            console.log(this.state.claimResult)
            let unfinalizeConfirmationModal = <Modal show={this.state.reopenClaimApproval}
                                                     onHide={this.handleUnfinalizeConfirmModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Reopen Claim</Modal.Title>
                </Modal.Header>
                <Modal.Body>Reopening this claim will add back the claim amount to the employee. Would you like to proceed?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleUnfinalizeConfirmModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={this.unfinalizeClaim}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            
            let responseContents = null;
            if (this.state.claimResult !== null && this.state.claimResult !== undefined){
                responseContents = (
                    <div>
                        <div>
                        <table style={{width: "600px", fontSize: "20px"}}>
                            <tr>
                                <th style={{width: "35%"}}>Claim No</th>
                                <td>{this.state.claimNo}</td>
                            </tr>
                            <tr>
                                <th>Total Approved</th>
                                <td>
                                    <div className="ktm-funding-balance">
                                        ${this.state.claimResult.totalApproved.toLocaleString(
                                        "en-US",
                                        {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                    )}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>Adjudicated At</th>
                                <td>{moment(this.state.claimResult.adjudicatedAt).local().format('YYYY MMM DD')}</td>
                            </tr>
                            <tr>
                                <th>Adjudicator</th>
                                <td>{this.state.claimResult.username}</td>
                            </tr>
                        </table>
                        <div style={{marginTop: "10px"}}>
                            {!this.state.isClaimUnfinalized ?
                                <button onClick={this.setReopenClaim}
                                        className="btn btn-outline-primary"
                                        type="button">
                                    Unfinalize
                                </button>
                                : <p style={{color: "red", fontSize: "25px", padding: "10px"}}>The claim has been reopened.</p>
                            }
                        </div>
                        </div>
                    </div>
                )
            }

            return (
                <div>
                    <div className="ktm-financials-content">
                        <div className="input-group mb-3">
                            <input
                                onKeyDown={this.handleKeyDown}
                                type="text"
                                className="form-control"
                                name="searchInput"
                                onChange={(e) => this.setInput(e)}
                                value={this.state.claimNo}
                                placeholder={
                                    "Enter claim number"
                                }
                            />
                            <button
                                onClick={() => {this.submitSearch()}}
                                className="btn btn-outline-primary"
                                type="button"
                            >
                                Search
                            </button>
                        </div>
                    </div>
                    {responseContents}
                    {unfinalizeConfirmationModal}
                </div>
            );
        }
    }
)